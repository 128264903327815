<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Bank</h3>
              </div>
              <div class="card-button">
                <create />
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                loading-text="..."
                no-data-text="Malumot kiritilmagan"
                :items-per-page="10"
                single-select
                @click:row="rowClick"
                item-key="id"
                class="row-pointer"
                hide-default-footer
              >
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div>
                </template>
                <template v-slot:[`item.oper_date`]="{ item }">
                  {{ item.oper_date | formatDate }}
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                  {{ item.created_at | formatDate }}
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  {{ item.amount.toLocaleString('zh-ZH') }}
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <action :index="item" />
                </template>
              </v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="7"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import action from "./action";
// import create from "./create";
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './actiondetails'
export default {
  components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Turi', value: 'order_type_name' },
        { text: 'Debet', value: 'amountDebet' },
        { text: 'Kredit', value: 'amount' },
        { text: 'Sana', value: 'oper_date', width: '100px' },
        { text: 'Transfer nomi', value: 'type_statetransfer_name',width: '250px' },
        { text: 'Karta nomeri', value: 'corpcard_info.card_number' },
        { text: 'Karta egasi', value: 'corpcard_info.card_owner',width: '150px' },
        { text: 'Klient nomi', value: 'client_info.client_name',width: '150px' },
        { text: 'Klient kontrakt shartnomasi', value: 'client_info.contract_number',width: '150px' },
        { text: 'Kontragent nomi', value: 'contragent_info.contract_number',width: '150px' },
        { text: 'Kontragent kontrakt shartnomasi', value: 'contragent_info.contract_number',width: '150px' },
        { text: 'Yetkazib beruvchi nomi', value: 'provider_info.provider_name',width: '150px' },
        { text: 'Yetkazib beruvchi shartnomasi', value: 'provider_info.contract_number',width: '150px' },
        { text: 'Yaratilgan sana', value: 'created_at', width: '100px' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push(
          '/vipiska/bank/' +
            this.$route.params.source +
            '/' +
            item.oper_date +
            '/' +
            item.id
        )
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.replace({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      const data = {
        page: value,
        payment_source: this.$route.params.source,
        oper_date: this.$route.params.date
      }
      this.$store.dispatch('TransactionsDailyDetails', data)
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    list() {
      const data = this.$store.state.requests.transactionsDailyDetails.results
      return data
    },
    getCount() {
      return this.$store.state.requests.transactionsDailyDetails
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Vipiska' },
      { title: 'Bank' }
    ])
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-data-table__wrapper {
    overflow-x: scroll !important;
    overflow-y: unset !important;
}
</style>
